


































import { Order, Source } from "@/models";
import {
  Vue,
  Component,
  Prop,
  Mixins,
  Watch,
  Ref
} from "vue-property-decorator";

import { Getter, Action, namespace } from "vuex-class";
import WarehouseItemsMixin from "@/mixins/http/WarehouseItemsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import WarehouseItemData from "@/components/WarehouseItem/Form.vue";
import PrintData from "@/components/WarehouseItem/PrintData.vue";
import { RequestData } from "@/store/types/RequestStore";
import { showErrorToast, showToast } from "@/utils/Toast";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import ManifactureData from "@/components/WarehouseItem/ManifactureData.vue";
import WarehouseDiffsData from "@/components/WarehouseItem/DiffsData.vue";
import { WarehouseItem } from "@/models";
import { easync } from "@/utils/http";

const getItemTag = "get_warehouse_item";
const updateItemTag = "update_warehouse_item";

@Component({
  components: {
    WarehouseDiffsData,
    WarehouseItemData,
    ManifactureData,
    PrintData,
    VPageContentSticky
  }
})
export default class WarehouseItemShow extends Mixins(WarehouseItemsMixin) {
  @RegisterHttp(getItemTag) getItemRequest: RequestData;
  @RegisterHttp(updateItemTag) updateItemRequest: RequestData;
  @Ref() diffsData: HTMLElement;
  item: WarehouseItem = null;

  mounted() {
    this.load();
  }

  get id() {
    return parseInt(this.$route.params.id);
  }

  async update(data: any = null) {
    const payload = { id: this.item.id, ...data };

    const [res, errors] = await easync(
      this.updateWarehouseItem(payload, updateItemTag)
    );

    if (errors) {
      showErrorToast("Errore nell'aggiornamento dell'articolo");
      return;
    }

    if (this.item.quantity !== res.quantity) {
      (this.diffsData as any).load();
    }
    this.item = res;
    showToast("Articolo aggiornato!");
  }

  async load() {
    const [res, errors] = await easync(
      this.getWarehouseItem(this.id, getItemTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.item = res;
  }

  get loading() {
    return this.getItemRequest?.loading;
  }

  get meta() {
    return this.getItemRequest?.meta;
  }
}
