























import { Vue, Component, Prop, Mixins, Ref } from 'vue-property-decorator';
import WarehouseItemManufacturesMixin from '@/mixins/http/WarehouseItemManufacturesMixin';
import { RegisterHttp } from '@/utils/Decorators';
import CreateModal from '@/components/WarehouseItemManufacture/Create.vue';
import EditModal from '@/components/WarehouseItemManufacture/Edit.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';

import { RequestData } from '@/store/types/RequestStore';
import DataTable from '@monade/vue-components/src/components/DataTable.vue';

import { WarehouseItemManufacture } from '@/models';
import { showErrorToast, showToast } from '@/utils/Toast';
import { easync } from '@/utils/http';
const tag = 'get_warehouse_item_manufactures';
const destroyTag = 'destroy_warehouse_item';

@Component({
  components: {
    CreateModal,
    ConfirmModal,
    EditModal,
    DataTable
  }
})
export default class ManifactureData extends Mixins(
  WarehouseItemManufacturesMixin
) {
  @Prop()
  warehouseItemId: string;

  @Ref() createModal: HTMLDivElement;
  @Ref() editModal: HTMLDivElement;
  @Ref() confirmModal: HTMLDivElement;

  @RegisterHttp(tag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;

  items: WarehouseItemManufacture[] = [];

  counter = 0;

  openCreateModal() {
    (this.createModal as any).show({ warehouseItemId: this.warehouseItemId });
  }

  openEditModal(item: WarehouseItemManufacture) {
    (this.editModal as any).show(item);
  }

  async openConfirmModal(item: WarehouseItemManufacture) {
    const [data, errors] = await easync(
      (this.confirmModal as any).confirm('Sicuro di voler eliminare la lavorazione?')
    );

    if (errors) {
      showErrorToast();
      return;
    }

    if (data) {
      this.destroy(item.id);
    }
  }

  async destroy(id: string) {
    const [data, errors] = await easync(
      this.destroyWarehouseItemManufacture(id, destroyTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.onDeleted(id);
    showToast('Lavorazione eliminata!');
  }

  onDeleted(id: string) {
    this.items = this.items.filter(o => id !== o.id);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  mounted() {
    this.load();
  }

  onUpdated() {
    this.load();
  }

  get itemsGroupedByPrintTechnology() {
    return this.groupBy(this.items, (item: any) => item.printTechnology.code);
  }

  groupBy(list: any[], keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  onCreated(item: WarehouseItemManufacture) {
    this.load();
  }

  async load() {
    const [data, errors] = await easync(
      this.getWarehouseItemManufactures({ warehouseItem: this.warehouseItemId }, tag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

     this.items = data;
  }
}
