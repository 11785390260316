




















































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import VModal from '@/components/VModal.vue';
import WarehouseItemManufacturesMixin from '@/mixins/http/WarehouseItemManufacturesMixin';
import PrintTechnologySelect from '@/components/PrintTechnology/Select.vue';
import ManufactureSelect from '@/components/Manufacture/Select.vue';
import WarehouseItemSelect from '@/components/WarehouseItem/Select.vue';
import { WarehouseItem } from '@/models';
import { RequestData } from '@/store/types/RequestStore';
import { RegisterHttp } from '@/utils/Decorators';
import { WarehouseItemManufacture } from '@/models';
import DisplayErrors from '@/components/DisplayErrors.vue';
import { CreateWarehouseItemManufacture } from '@/models/WarehouseItemManufacture';
import { easync } from '@/utils/http';
import { showErrorToast } from '@/utils/Toast';
const editRequestTag = 'edit_warehouse_item_manufacture_request_tag';

@Component({
  components: {
    VModal,
    DisplayErrors,
    PrintTechnologySelect,
    ManufactureSelect,
    WarehouseItemSelect
  }
})
export default class EditModal extends Mixins(WarehouseItemManufacturesMixin) {
  @RegisterHttp(editRequestTag) editRequest: RequestData;

  item: WarehouseItemManufacture = null;
  modal!: VModal;
  content = '';

  prototype: CreateWarehouseItemManufacture = new CreateWarehouseItemManufacture();

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  get valid() {
    if (!this.prototype) {
      return false;
    }
    return (
      this.prototype.warehouseItemId &&
      this.prototype.printTechnologyId &&
      this.prototype.manufactureId
    );
  }

  title() {
    return `Aggiorna lavorazione per tecnologia di stampa`;
  }

  async update() {
    const [data, errors] = await easync(
      this.updateWarehouseItemManufacture({ id: this.item.id, ...this.prototype }, editRequestTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.$emit('success', this.item);
    this.modal.hide();
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.valid) {
      this.update();
    }
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  show(item: WarehouseItemManufacture) {
    this.prototype.warehouseItemId = item.warehouseItem.id;
    this.prototype.printTechnologyId = item.printTechnology.id;
    this.prototype.manufactureId = item.manufacture.id;
    this.item = item;
    this.modal.show();
  }
}
