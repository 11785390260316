











































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import VModal from '@/components/VModal.vue';
import PrintTechnologySelect from '@/components/PrintTechnology/Select.vue';
import ManufactureSelect from '@/components/Manufacture/Select.vue';
import WarehouseItemSelect from '@/components/WarehouseItem/Select.vue';
import WarehouseItemManufacturesMixin from '@/mixins/http/WarehouseItemManufacturesMixin';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import { CreateWarehouseItemManufacture } from '@/models/WarehouseItemManufacture';
import { showErrorToast, showToast } from '@/utils/Toast';
import { WarehouseItemManufacture } from '@/models';
import { easync } from '@/utils/http';
const createRequestTag = 'create_request_tag';
@Component({
  components: {
    VModal,
    PrintTechnologySelect,
    WarehouseItemSelect,
    ManufactureSelect
  }
})
export default class CreateModal extends Mixins(
  WarehouseItemManufacturesMixin
) {
  @RegisterHttp(createRequestTag) createRequest: RequestData;

  modal!: VModal;
  content = '';

  prototype: CreateWarehouseItemManufacture = new CreateWarehouseItemManufacture();

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  loading() {
    return this.createRequest?.loading;
  }

  get valid() {
    return (
      this.prototype.printTechnologyId &&
      this.prototype.manufactureId &&
      this.prototype.warehouseItemId
    );
  }

  title() {
    return `Nuova lavorazione per tecnologia di stampa`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
    this.reset();
  }

  async create() {
    const [data, errors] = await easync(
      this.createWarehouseItemManufacture({ ...this.prototype }, createRequestTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.$emit('success', data);
    showToast('Salvataggio riuscito!');
    this.modal.hide();
    this.reset();
  }

  protected onConfirm() {
    this.create();
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  reset() {
    this.prototype = new CreateWarehouseItemManufacture();
  }

  show(payload: CreateWarehouseItemManufacture) {
    this.prototype = Object.assign(this.prototype, payload);
    this.modal.show();
  }
}
