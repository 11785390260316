




import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import Chart, {ChartDataSets, ChartConfiguration} from "chart.js";
import ColorHelper from "@/services/ColorHelper";
import NumberFormatter from "@/services/NumberFormatter";
import { THEME_COLORS } from "@/services/ColorHelper";

export interface CustomChartItem {
  label: string;
  color?: string;
  data: number[];
}

export const CustomChartPieDefaultOptions: any = {
  layout: {

    padding: {
      left: 32,
      right: 32,
      top: 0,
      bottom: 0
    }
  },
  legend: {
    position: "right",
    align: "center",
    labels: {
      padding: 11
    }
  }
};

export const CustomChartDoughnutDefaultOptions: any = {
  cutoutPercentage: 70,
  layout: {
    padding: {
      left: 32,
      right: 32,
      top: 0,
      bottom: 0
    }
  },
  legend: {
    position: "right",
    align: "center",
    labels: {
      padding: 11
    }
  }
};

@Component
export default class CustomChart extends Vue {
  @Prop({ default: "bar" }) type?: string;
  @Prop({ default: false }) stacked?: boolean;
  @Prop({ default: false }) percentage?: boolean;
  @Prop({ default: 90 }) pointsLimit?: number;
  @Prop({ default: true }) showLegend!: boolean;
  @Prop({ default: () =>  {return{}}}) canvasStyle!: any;

  @Prop() labels?: string[];
  @Prop() items!: Array<CustomChartItem>;
  @Prop() options?: any;

  @Watch("type")
  onTypeChange() {
    this.reload();
  }

  @Watch("stacked")
  onValueChange() {
    if (this.chart) {
      this.chart.options = this.chartOptions;

      if (this.isLine) {
        this.chart.data.datasets = this.getChartDataSets();
      }

      this.updateChart();
    }
  }

  @Watch("items", { deep: true })
  onItemsChange() {
    if (this.chart) {
      this.chart.data.labels = this.chartLabels;
      this.chart.data.datasets = this.getChartDataSets();
      this.updateChart();
    }
  }

  @Watch("percentage")
  onPercentageChange() {
    if (this.chart) {
      this.chart.options = this.chartOptions;
      this.onItemsChange();
    }
  }

  chart!: any;
  hiddenItems: number[] = [];

  get isBar(): boolean {
    return this.type === "bar";
  }

  get isPie(): boolean {
    return this.type === "pie";
  }

  get isLine(): boolean {
    return this.type === "line";
  }

  get isDoughnut(): boolean {
    return this.type === "doughnut";
  }

  get isCircular(): boolean {
    return this.isPie || this.isDoughnut;
  }

  mounted() {
    this.createChart();
  }

  createChart() {
    this.chart = new Chart(
      this.$refs.chart as HTMLCanvasElement,
      this.chartConfiguration
    );
  }

  reload() {
    this.chart.destroy();
    this.createChart();
    this.updateItemVisibility();
  }

  updateItemVisibility() {
    this.chart?.data?.datasets?.forEach((e: any, i: number) => {
      const meta = this.chart.getDatasetMeta(i);
      const hiddenIndex = this.hiddenItems.indexOf(i);

      if (hiddenIndex !== -1) {
        meta.hidden = true;
      }
    });
  }

  updateChart() {
    this.updateItemVisibility();
    this.chart.update();
  }

  get chartConfiguration(): any {
    return {
      type: this.type,
      data: {
        labels: this.chartLabels,
        datasets: this.getChartDataSets()
      },
      options: this.chartOptions
    };
  }

  get chartOptions(): any {
    if (this.options) {
      return this.options;
    }

    if (this.isPie) {
      return CustomChartPieDefaultOptions
    }

    if(this.isDoughnut) {
      return CustomChartDoughnutDefaultOptions;
    }

    return {

      scales: {
        yAxes: [
          {
            stacked: this.stacked,
            ticks: {
              callback: this.formatYAxesLabel,
              max: this.percentage ? 100 : undefined,
              min: this.percentage ? 0 : undefined,
              stepSize: this.percentage ? 10 : undefined
            }
          }
        ],
        xAxes: [
          {
            stacked: this.stacked
          }
        ]
      },
      legend: {
        display: this.showLegend,
        onClick: (event: any, item: any) => {
          const index = item.datasetIndex;

          if (index !== undefined) {
            this.toggleItemVisibility(index);
          }
        }
      }
    };
  }

  getChartDataSets(): any[] {
    ColorHelper.reset();

    if (this.isCircular) {
      return this.getPieDataSet();
    }

    const items = this.percentage ? this.getItemsPercentage() : this.items;

    return items.map((item: CustomChartItem) => {
      const color = item.color || THEME_COLORS.primary;
      const borderColor =
        this.isLine && this.stacked ? ColorHelper.adjust(color, -32) : color;

      return {
        label: item.label,
        borderColor: borderColor,
        backgroundColor: color,
        data: item.data,
        maxBarThickness: 26,
        categoryPercentage: 0.5,
        fill: !this.isLine || (this.isLine && this.stacked),
        lineTension: 0.2,
        borderWidth: 2,
        pointRadius:
          this.pointsLimit && this.pointsLimit < item.data.length ? 0 : 2,
        pointBorderWidth: 1,
        pointBackgroundColor: color,
        pointBorderColor: this.stacked ? "#ffffff" : color
      };
    });
  }

  getPieDataSet(): any[] {
    let data: number[] = [];
    const colors: string[] = [];

    this.items?.forEach((item: CustomChartItem) => {
      const color = item.color || ColorHelper.pick();
      colors.push(color);

      const total = item.data.reduce((previous: number, current: number) => {
        return previous + current;
      }, 0);

      data.push(total);
    });

    if (this.percentage) {
      const total = data.reduce((previous: number, current: number) => {
        return previous + current;
      }, 0);

      data = data.map((value: number) => {
        return Math.round((value / total) * 100);
      });
    }

    return [
      {
        data: data,
        backgroundColor: colors
      }
    ];
  }

  get chartLabels(): string[] | undefined {
    if (this.isCircular) {
      const labels: string[] = [];

      this.items?.forEach((item: CustomChartItem) => {
        labels.push(item.label);
      });

      return labels;
    } else {
      return this.labels;
    }
  }

  getItemsPercentage(): CustomChartItem[] {
    if (!this.labels) {
      return [];
    }

    const totals = this.labels.map(() => {
      return 0;
    });

    const items = this.getClonedItems();

    items.map((item: CustomChartItem) => {
      item.data.map((value: number, index: number) => {
        if (typeof totals[index] !== undefined) {
          totals[index] += value;
        }
      });
    });

    return items.map((item: CustomChartItem) => {
      item.data = item.data.map((value: number, index: number) => {
        if (totals[index]) {
          return Math.round((value / totals[index]) * 100);
        } else {
          return value;
        }
      });

      return item;
    });
  }

  getClonedItems(): CustomChartItem[] {
    const items: CustomChartItem[] = [];
    this.items.forEach(item => items.push(Object.assign({}, item)));

    return items;
  }

  formatYAxesLabel(value: number): string | number | null | undefined {
    if (this.percentage) {
      return `${value.toString()}%`;
    } else {
      return NumberFormatter.beautify(value);
    }
  }

  toggleItemVisibility(index: number) {
    const alreadyHidden =
      this.chart.getDatasetMeta(index).hidden === null
        ? false
        : this.chart.getDatasetMeta(index).hidden;

    this.chart?.data?.datasets?.forEach((e: any, i: number) => {
      const meta = this.chart.getDatasetMeta(i);

      if (i == index) {
        if (alreadyHidden) {
          meta.hidden = false;
          const hiddenIndex = this.hiddenItems.indexOf(index);
          this.hiddenItems.splice(hiddenIndex, 1);

          this.$emit("item-visible", index);
        } else {
          meta.hidden = true;
          this.hiddenItems.push(index);
          this.$emit("item-hidden", index);
        }
      }
    });

    this.chart.update();
  }
}
