







































import { WarehouseItem } from '@/models';
import { Vue, Component, Prop } from 'vue-property-decorator';
import MultiColorInput from '@/components/MultiColorInput.vue';

@Component({
  components: {
    MultiColorInput
  }
})
export default class WarehouteItemForm extends Vue {
  @Prop() item: WarehouseItem;

  emitUpdate(data: WarehouseItem) {
    this.$emit('updated', data);
  }

  private colors = ['#000000'];
}
