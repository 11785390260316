
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_WAREHOUSE_ITEM_URL = BASE_API_URL+"warehouse_item_manufactures"

@Component
export default class WarehouseItemManufacturesMixin extends Vue {

  getWarehouseItemManufactures(data: any, tag: string) {
    return this.$httpGetQuery(BASE_WAREHOUSE_ITEM_URL, tag, data);
  }

  destroyWarehouseItemManufacture(id: string|number, tag: string) {
    return this.$httpDelete(BASE_WAREHOUSE_ITEM_URL+"/"+id, tag);
  }

  createWarehouseItemManufacture(data: any, tag: string) {
    return this.$httpPost(BASE_WAREHOUSE_ITEM_URL, tag, data);
  }

  updateWarehouseItemManufacture(data: any, tag: string) {
     return this.$httpPut(BASE_WAREHOUSE_ITEM_URL+"/"+data.id, tag, data);
  }
}
