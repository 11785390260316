


































import moment from 'moment';
import { Vue, Component, Prop, Mixins, Ref } from 'vue-property-decorator';
import WarehouseItemsMixin from '@/mixins/http/WarehouseItemsMixin';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import ColorHelper from '@/services/ColorHelper';
import DatePicker from '@/components/DatePicker.vue';
import { WarehouseItemManufacture } from '@/models';
import { showErrorToast, showToast } from '@/utils/Toast';
import { datesRange } from '@/utils/Date';
import CustomChart from '@/components/CustomChart.vue';
import { DatePickerData } from '../DatePicker.vue';
import { easync } from '@/utils/http';

const tag = 'get_warehouse_item_stats';

export class WarehouseItemStats {
  label: Date = null;
  value = 0;
}

export class WarehouseItemStatsData {
  start: number;
  series: WarehouseItemStats[];
}

export interface WarehouseDiffFilter {
  date_from: string;
  date_to: string;
  warehouse_item_id: string | number;
}

@Component({
  components: { CustomChart, DatePicker }
})
export default class DiffsData extends Mixins(WarehouseItemsMixin) {
  @Prop()
  warehouseItemId!: string;

  stats: WarehouseItemStatsData = null;

  get lastDate() {
    return moment()
      .add(1, 'days')
      .format('YYYY-MM-DD');
  }
  private filter: WarehouseDiffFilter = {
    date_to: moment()
      .add(1, 'days')
      .format('YYYY-MM-DD'),
    warehouse_item_id: this.warehouseItemId,
    date_from: moment()
      .subtract(1, 'weeks')
      .format('YYYY-MM-DD')
  };

  onDateSelected(data: DatePickerData) {
    this.filter.date_from = data.range.from.toString();
    this.filter.date_to = data.range.to.toString();
    this.load();
  }

  @RegisterHttp(tag) getRequest: RequestData;

  get chartData() {
    if (!this.stats) {
      return undefined;
    }

    const series = this.stats.series;
    const start = this.stats.start;
    let data = [];
    for (let i = 0; i < series.length; i++) {
      let newStat = { ...series[i] };
      if (i === 0) {
        newStat.value += start;
      } else {
        newStat.value = data[i - 1].value + series[i]?.value;
      }
      data.push(newStat);
    }
    return [{ data: data?.map(o => o.value) || [] }];
  }

  get randomColor() {
    return ColorHelper.random();
  }

  get chartLabels() {
    return this.stats?.series.map(o => o.label) || [];
  }

  async load() {
    const [data, errors] = await easync(
      this.getWarehouseDiffStats(parseInt(this.warehouseItemId), this.filter, tag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.stats = data.stats;
  }

  get loading() {
    return this.getRequest?.loading;
  }

  mounted() {
    this.load();
  }
}
